
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import { countryList, parseTime } from "@/utils";
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { categoryGroupList, getShopPropconfList } from "@/api/request/item";
import { addShopMall, editShopMall, getShopMallList, setShopMallStatus } from "@/api/request/mall";
import DTCls from "@/utils/datetime";

//组件
@Component({
  name: "MallDiamond",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private mallType: number = 2;
  private countryList: any[] = [];
  private propconfList: any[] = [];
  private categoryList: any[] = [];
  private listLoading: boolean = true;

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    row: 20,

    //请求数据
    name: "",
    status: "",
    country: "",
    type: this.mallType,
    cat_id: 0,
  };

  //商品状态
  private productStatus: any[] = [
    { type: "", name: "全部" },
    { type: "0", name: "下架" },
    { type: "1", name: "上架" },
    { type: "2", name: "删除" },
  ];

  //商品状态
  private productStatusEx: any[] = [
    { type: 0, name: "下架" },
    { type: 1, name: "上架" },
    { type: 2, name: "删除" },
  ];

  //创建
  async created() {
    //数据赋值
    this.countryList = countryList;

    //获取类别列表
    await this.getCategoryList();

    //获取道具列表
    this.getShopPropconfList();

    //获取数据
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getShopMallList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //语言数据
      name_list: {
        zh: "",
        en: "",
        id: "",
      },

      //基本参数
      id: 0, //商品ID
      cnt: 0, //商品价格
      sort: 0, //排列顺序
      status: 0, //商品状态 0-下架 1-上架 2-删除
      type: this.mallType, //商品类型 1 钻石商城 2 互动币商城
      props: "", //道具集合 {1006:1,1002:2}
      country: "",

      //新增字段
      tag: "", // 标签图片
      image: "", // 商品图片
      brief: "", // 商品描述
      begin_time: 0, // 促销开始时间
      end_time: 0, // 促销结束时间
      price: 0, // 商品售价
      cat_id: [],
    };
    this.time = [];
    this.propData = [];
    this.tagUrl = null;
    this.price_str = "";
    this.imageUrl = null;
    this.tagFileList = [];
    this.imageFileList = [];
    this.dialogStatus = "create";

    //显示对话框
    this.dialogVisible = true;
  }

  //获取分类列表
  private async getCategoryList() {
    //获取数据
    this.listLoading = true;

    //获取数据
    const { data } = await categoryGroupList({});

    //数据赋值
    this.categoryList = [];
    this.categorySelect = [];
    for (var id in data) {
      if (Number(id) == 4) {
        for (var i: number = 0; i < data[id].length; i++) {
          this.categoryList.push(data[id][i]);
          this.categorySelect.push(data[id][i]);
        }
      }
    }
    this.categoryList.unshift({ id: 0, name: "全部分类" });
  }

  //获取道具列表
  private async getShopPropconfList() {
    //请求数据
    const reqParams = {
      id: 0,
      page: 1,
      giftid: 0,
      model: 0,
      propname: "",
      giftname: "",
      row: GFunc.getListRowLimit(),
    };

    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getShopPropconfList(reqParams);

    //数据赋值
    this.propconfList = data.list;
    this.propconfList.unshift({ id: 0, propname: "请选择" });
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.propData = [];
    this.tagUrl = null;
    this.imageUrl = null;
    this.tagFileList = [];
    this.imageFileList = [];
    const props = row.props || "{}";
    const data = JSON.parse(props);
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const prop: any = { id: 0, num: 0 };
      prop.id = GFunc.checkint(keys[i]);
      prop.num = GFunc.checkint(data[prop.id]);
      this.propData.push(prop);
    }
    this.dialogStatus = "update";
    this.addForm = GFunc.deepClone(Object.assign({}, row));
    if (GFunc.checkstring(row.tag)) {
      this.tagUrl = GFunc.checkstring(row.tag);
      this.tagFileList.push({ name: "name.jpg", url: GFunc.checkstring(row.tag) });
    }
    if (GFunc.checkstring(row.image)) {
      this.imageUrl = GFunc.checkstring(row.image);
      this.imageFileList.push({ name: "name.jpg", url: GFunc.checkstring(row.image) });
    }

    //临时数据
    this.price_str = row.price.toString();
    this.time = row.end_time != 0 && row.begin_time != 0 ? [parseTime(new Date(row.begin_time * 1000), "{y}-{m}-{d}"), parseTime(new Date(row.end_time * 1000), "{y}-{m}-{d}")] : [];

    //显示对话框
    this.dialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        //处理删除操作
        this.opMallProducts(row);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //返回时间
  private parseTime(time: any): string {
    return parseTime(time) as string;
  }

  //处理删除操作
  private async opMallProducts(row: any) {
    //数据赋值
    await setShopMallStatus({ id: row.id, status: 2 });

    //数据赋值
    const index = this.list.findIndex((v) => v.id === row.id);
    this.list.splice(index, 1);

    //显示提示
    this.$notify({
      title: "成功",
      message: "成功删除公告数据",
      type: "success",
      duration: 2000,
    });
  }

  //道具列表
  private getPropsList(props: any): any[] {
    //定义变量
    var propsList: any[] = [];

    //数据赋值
    for (var id in props) {
      //数据赋值
      var name: string = "";
      for (var i: number = 0; i < this.propconfList.length; i++) {
        if (Number(id) == this.propconfList[i].id) {
          name = this.propconfList[i].propname;
          break;
        }
      }
      propsList.push({ id: id, name: name, num: props[id] });
    }

    //返回结果
    return propsList;
  }

  //名称处理
  private changeToReal(value: any): string {
    return GFunc.checkstring(value) ? value : "--";
  }

  //获取状态名称
  private getProductStatusName(type: any): string {
    //定义变量
    let name: string = "--";

    //数据赋值
    type = GFunc.checkstring(type);
    for (let i = 0; i < this.productStatus.length; i++) {
      const pro = this.productStatus[i];
      if (type === pro.type) {
        name = pro.name;
        break;
      }
    }

    //返回结果
    return name;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //获取分类名称
  private getCategoryName(ids: Array<number>): string {
    //定义变量
    var str: string = "";
    if (ids.length == 1 && ids[0] == 0) {
      str = "全部分类";
    } else {
      for (var i: number = 0; i < ids.length; i++) {
        for (var j: number = 0; j < this.categoryList.length; j++) {
          if (ids[i] == this.categoryList[j].id) {
            str += this.categoryList[j].name + ",";
            break;
          }
        }
      }
      if (str.length > 0) str = str.substring(0, str.length - 1);
    }

    //返回分类名称
    return str;
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private time: any[] = [];
  private propData: any[] = [];
  private price_str: string = "";
  private dialogStatus: string = "";
  private categorySelect: any[] = [];
  private dialogVisible: boolean = false;
  private titleMap = { update: "编辑商品", create: "新增商品" };

  //添加表单
  private addForm: any = {
    //语言数据
    name_list: {
      zh: "",
      en: "",
      id: "",
    },

    //基本参数
    id: 0, //商品ID
    cnt: 0, //商品价格
    sort: 0, //排列顺序
    status: 0, //商品状态 0-下架 1-上架 2-删除
    type: this.mallType, //商品类型 1 钻石商城 2 互动币商城
    props: "", //道具集合 {1006:1,1002:2}
    country: "",

    //新增字段
    tag: "", // 标签图片
    image: "", // 商品图片
    brief: "", // 商品描述
    begin_time: 0, // 促销开始时间
    end_time: 0, // 促销结束时间
    price: 0, // 商品售价
    cat_id: [],
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //增加商品道具
  private addProp(): void {
    //数据赋值
    this.propData.push({ id: 0, num: 0 });

    //滚动到最下面
    this.$nextTick(() => {
      (this.$refs.cList as any).scrollTop = (this.$refs.cList as any).scrollHeight;
    });
  }

  //创建商品
  private async createData() {
    //数据赋值
    var props: any = {};
    var propData: any[] = [];
    for (var i: number = 0; i < this.propData.length; i++) {
      if (this.propData[i].id != 0 && Number(this.propData[i].num) != 0) {
        propData.push(this.propData[i]);
        props[this.propData[i].id] = Number(this.propData[i].num);
      }
    }

    //判断返回
    if (this.addForm.name_list.zh == "") return Message({ message: "请输入道具名称(中文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.en == "") return Message({ message: "请输入道具名称(英文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.id == "") return Message({ message: "请输入道具名称(印尼)！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.cnt) == 0) return Message({ message: "请输入商品价格！", type: "error", duration: 5 * 1000 });
    if (propData.length == 0) return Message({ message: "请设置商品道具！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.props = JSON.stringify(props);
    this.addForm.cnt = GFunc.checkint(this.addForm.cnt);
    this.addForm.sort = GFunc.checknumber(this.addForm.sort);
    this.addForm.type = GFunc.checkint(this.mallType);
    this.addForm.price = Number(this.price_str);
    this.addForm.image = this.imageUrl == "" ? null : this.imageUrl;
    this.addForm.tag = this.tagUrl == "" ? null : this.tagUrl;

    //时间赋值
    if (this.time && this.time.length == 2) {
      this.addForm.end_time = Math.floor(new Date(this.time[1] + " 23:59:59").getTime() / 1000);
      this.addForm.begin_time = Math.floor(new Date(this.time[0] + " 00:00:00").getTime() / 1000);
    } else {
      this.addForm.end_time = 0;
      this.addForm.begin_time = 0;
    }

    //添加
    await addShopMall(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }

  //更新商品
  private async updateData() {
    //数据赋值
    var props: any = {};
    var propData: any[] = [];
    for (var i: number = 0; i < this.propData.length; i++) {
      if (this.propData[i].id != 0 && Number(this.propData[i].num) != 0) {
        propData.push(this.propData[i]);
        props[this.propData[i].id] = Number(this.propData[i].num);
      }
    }

    //判断返回
    if (this.addForm.name_list.zh == "") return Message({ message: "请输入道具名称(中文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.en == "") return Message({ message: "请输入道具名称(英文)！", type: "error", duration: 5 * 1000 });
    if (this.addForm.name_list.id == "") return Message({ message: "请输入道具名称(印尼)！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.cnt) == 0) return Message({ message: "请输入商品价格！", type: "error", duration: 5 * 1000 });
    if (propData.length == 0) return Message({ message: "请设置商品道具！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.props = JSON.stringify(props);
    this.addForm.cnt = GFunc.checkint(this.addForm.cnt);
    this.addForm.sort = GFunc.checknumber(this.addForm.sort);
    this.addForm.type = GFunc.checkint(this.mallType);
    this.addForm.price = Number(this.price_str);
    this.addForm.image = this.imageUrl == "" ? null : this.imageUrl;
    this.addForm.tag = this.tagUrl == "" ? null : this.tagUrl;

    //时间赋值
    if (this.time && this.time.length == 2) {
      this.addForm.end_time = Math.floor(new Date(this.time[1] + " 23:59:59").getTime() / 1000);
      this.addForm.begin_time = Math.floor(new Date(this.time[0] + " 00:00:00").getTime() / 1000);
    } else {
      this.addForm.end_time = 0;
      this.addForm.begin_time = 0;
    }

    //添加
    await editShopMall(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //删除道具
  private removeProp(item: any): void {
    //显示提示
    MessageBox.confirm("确定删除该道具吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        //数据赋值
        const index = this.propData.indexOf(item);
        if (index !== -1) this.propData.splice(index, 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //--------------------------------- 图片处理 ---------------------------------
  //定义变量
  private tagUrl: any = null;
  private imageUrl: any = null;
  private tagFileList: any[] = [];
  private imageFileList: any[] = [];
  private getHeads = getHeaders();
  private getAction = this.getApi();

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //图片动画预览
  private handleTagPreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示对话框
    this.dialogImageVisible = true;
  }

  //图片动画预览
  private handleImagePreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示对话框
    this.dialogImageVisible = true;
  }

  //上传文件
  private handleTagBeforeUploadCommon(file: any): any {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 10;

    //文件过大
    if (!isLt2M) {
      //显示提示
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出10M",
        type: "warning",
        duration: 2000,
      });

      //返回十八
      return false;
    }
    //格式不正确
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //上传文件
  private handleImageBeforeUploadCommon(file: any): any {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 13;

    //文件过大
    if (!isLt2M) {
      //显示提示
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出13M",
        type: "warning",
        duration: 2000,
      });

      //返回十八
      return false;
    }
    //格式不正确
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除图片
  private handleTagRemove(file: any, fileList: any): void {
    this.tagUrl = null;
    this.tagFileList = [];
  }

  //移除图片
  private handleImageRemove(file: any, fileList: any): void {
    this.imageUrl = null;
    this.imageFileList = [];
  }

  //上传图片成功
  private handleTagUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.tagFileList = fileList;
    this.tagUrl = response.data.images[0];
  }

  //上传图片成功
  private handleImageUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.imageFileList = fileList;
    this.imageUrl = response.data.images[0];
  }

  //--------------------------------- 显示图片 ---------------------------------
  //定义变量
  private dialogImageVisible = false;
  private dialogImageUrl: any = null;

  //打开图片
  private openImg(url: string) {
    //数据赋值
    this.dialogImageUrl = url;

    //显示界面
    this.dialogImageVisible = true;
  }
}
